import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='container py-4'>
      <h2 className='text-center my-3'>Privacy Policy</h2>
      <p>
        Bunno is an e-commerce platform that values your privacy. In this Privacy Policy, we describe the information that we collect about you when you visit the Bunno website and use the services available on Bunno, and how we use and disclose that information.
      </p>

      <h3>A. Collection of Your Personal Information</h3>

      <h5>COLLECTION OF PERSONAL INFORMATION</h5>
      <p>
        “Personal information” is defined to include information that whether on its own or in combination with other information may be used to readily identify or contact you such as: name, email address, phone number, etc., or information which is required to initiate a refund/exchange of the products offered by Bunno ("Bunno Products"), such as purchase information, payment details, shipping address, etc.
      </p>
      <p>
        At the time of creating an account, user data such as name, email ID, etc., shall be collected, and the user grants requisite permission for the same.
      </p>
      <p>
        Such information gathered shall be utilized to ensure greater customer satisfaction and help a customer meet their needs. The type of personal information that we collect from you varies based on your particular interaction with the Company.
      </p>

      <h5>COLLECTION OF AUTOMATIC INFORMATION, USE OF COOKIES, AND OTHER TRACKING DEVICES</h5>
      <p>
        We and our third-party service providers, which include ad networks, use cookies, web beacons, and other tracking technologies to collect information about your use of Bunno, such as your browser type, your ISP or operating system, your domain name, your access time, the URL of the previous website you visited, your page views, your IP address, and the type of device that you use. We also track how frequently you visit Bunno. We use this information to evaluate the success of our advertising campaigns and as otherwise described in this policy. Currently, we do not honor browser requests not to be tracked.
      </p>
      <p>
        We may obtain your device ID, which is sent to Bunno’s servers and used in fraud prevention efforts.
      </p>
      <p>
        Cookies: We and our third-party service providers collect information from you by using cookies. A cookie is a small file stored on the user's computer hard drive containing information about the user. The cookie helps us analyze web traffic or informs you about your use of a particular website. Cookies allow web applications to respond to you as an individual, tailoring its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences. When you visit Bunno, we may send one or more cookies to your computer that identifies your browser.
      </p>
      <p>
        Some of these cookies may be connected to third-party companies or websites. The terms of use of such cookies are governed by this Policy and the privacy policy of the relevant third-party company or website. If you visit Bunno when you have such cookies on your computer, we and Google will be able to tell that you saw the ad delivered by Google. The terms of use of these cookies are governed by this Policy and Google's Privacy Policy.
      </p>

      <h3>B. How Bunno Uses the Information We Collect</h3>

      <h5>HOW PERSONAL INFORMATION IS USED</h5>
      <p>
        We collect your personal information and aggregate information about the use of Bunno to better understand your needs and to provide you with a better experience. Specifically, we may use your personal information for any of the following reasons:
      </p>
      <ul>
        <li>
          To provide our services to you, including registering you for our services, verifying your identity and authority to use our services, and to otherwise enable you to use Bunno;
        </li>
        <li>For customer support and to respond to your inquiries;</li>
        <li>For internal record-keeping purposes;</li>
        <li>
          For shipping and delivery of Bunno Products and to initiate return/exchange and refunds;
        </li>
        <li>
          To process billing and payment, including sharing with third-party payment gateways in connection with Bunno;
        </li>
        <li>
          To improve and maintain Bunno (for example, we track information entered through the "Search" function; this helps us determine which areas of Bunno users like best and areas we can improve).
        </li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
